const state = {
  phaseOne: [
    {
      image: '/img/phaseOne/1.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 171.77,
              y: 221.45,
              w: 986.7,
              h: 836.486
            },
            name: 'Hyperinflation'
          },
          {
            coords: {
              x: 845.064,
              y: 374.92,
              w: 130.29,
              h: 276.844
            },
            name: 'Infiltrates'
          },
          {
            coords: {
              x: 477.596,
              y: 343.128,
              w: 115.596,
              h: 204.723
            },
            name: 'Infiltrates'
          }
        ]
      },
      reference: {
        time: 58540,
        diagnosis: 'Bronchiolitis',
        pathologies: [
          {
            coords: {
              x: 178,
              y: 215,
              w: 1012,
              h: 802
            },
            _id: '64776f91b1dcda0013e4ecd6',
            name: 'Hyperinflation'
          },
          {
            coords: {
              x: 873,
              y: 364,
              w: 129,
              h: 268
            },
            _id: '64776f91b1dcda0013e4ecd5',
            name: 'Infiltrates'
          },
          {
            coords: {
              x: 461,
              y: 348,
              w: 117,
              h: 207
            },
            _id: '64776f91b1dcda0013e4ecd4',
            name: 'Infiltrates'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/2.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 496.461,
              y: 554.631,
              w: 671.454,
              h: 588.35
            },
            name: 'Cardiomegaly'
          },
          {
            coords: {
              x: 136.092,
              y: 303.63,
              w: 1080.52,
              h: 1009.8
            },
            name: 'Hyperinflation'
          }
        ]
      },
      reference: {
        time: 54370,
        diagnosis: 'Congenital Cardiac , L-R shunt',
        pathologies: [
          {
            coords: {
              x: 503,
              y: 539,
              w: 657,
              h: 574
            },
            _id: '64777088b1dcda0013e4ecdb',
            name: 'Cardiomegaly'
          },
          {
            coords: {
              x: 132,
              y: 290,
              w: 1135,
              h: 990
            },
            _id: '64777088b1dcda0013e4ecda',
            name: 'Hyperinflation'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/3.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 402.615,
              y: 858,
              w: 490.728,
              h: 103.5
            },
            name: 'Pneumomediastinum'
          },
          {
            coords: {
              x: 784.32,
              y: 49.98,
              w: 95.04,
              h: 555.458
            },
            name: 'Pneumomediastinum'
          },
          {
            coords: {
              x: 517.12,
              y: 53.46,
              w: 85.096,
              h: 412.632
            },
            name: 'Pneumomediastinum'
          },
          {
            coords: {
              x: 959.408,
              y: 251.489,
              w: 137.995,
              h: 437.646
            },
            name: 'Pneumothorax'
          },
          {
            coords: {
              x: 839.777,
              y: 407.934,
              w: 175.084,
              h: 461.277
            },
            name: 'Infiltrates'
          },
          {
            coords: {
              x: 364.693,
              y: 289.365,
              w: 159.355,
              h: 257.048
            },
            name: 'Consolidation'
          }
        ]
      },
      reference: {
        time: 45297,
        diagnosis: 'Pneumothorax, Pneumomediastinum',
        pathologies: [
          {
            coords: {
              x: 389,
              y: 880,
              w: 483,
              h: 100
            },
            _id: '6478c13eb1dcda0013e4ed69',
            name: 'Pneumomediastinum'
          },
          {
            coords: {
              x: 760,
              y: 51,
              w: 99,
              h: 578
            },
            _id: '6478c13eb1dcda0013e4ed68',
            name: 'Pneumomediastinum'
          },
          {
            coords: {
              x: 505,
              y: 55,
              w: 88,
              h: 396
            },
            _id: '6478c13eb1dcda0013e4ed67',
            name: 'Pneumomediastinum'
          },
          {
            coords: {
              x: 976,
              y: 259,
              w: 143,
              h: 418
            },
            _id: '6478c13eb1dcda0013e4ed66',
            name: 'Pneumothorax'
          },
          {
            coords: {
              x: 829,
              y: 393,
              w: 169,
              h: 479
            },
            _id: '6478c13eb1dcda0013e4ed65',
            name: 'Infiltrates'
          },
          {
            coords: {
              x: 371,
              y: 303,
              w: 157,
              h: 253
            },
            _id: '6478c13eb1dcda0013e4ed64',
            name: 'Consolidation'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/4.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 182.886,
              y: 503.316,
              w: 54.72,
              h: 557.208
            },
            name: 'Pleural Effusion'
          },
          {
            coords: {
              x: 841.92,
              y: 130.375,
              w: 259.845,
              h: 277.916
            },
            name: 'Pneumothorax'
          },
          {
            coords: {
              x: 270.66,
              y: 317.695,
              w: 318.12,
              h: 618.12
            },
            name: 'Consolidation'
          },
          {
            coords: {
              x: 882.576,
              y: 410.31,
              w: 255.672,
              h: 457.92
            },
            name: 'Consolidation'
          }
        ]
      },
      reference: {
        time: 44166,
        diagnosis: 'ARDS, multilobar pneumonia',
        pathologies: [
          {
            coords: {
              x: 187,
              y: 492,
              w: 57,
              h: 568
            },
            _id: '6478c036b1dcda0013e4ed62',
            name: 'Pleural Effusion'
          },
          {
            coords: {
              x: 877,
              y: 125,
              w: 255,
              h: 268
            },
            _id: '6478c036b1dcda0013e4ed61',
            name: 'Pneumothorax'
          },
          {
            coords: {
              x: 260,
              y: 313,
              w: 330,
              h: 606
            },
            _id: '6478c036b1dcda0013e4ed60',
            name: 'Consolidation'
          },
          {
            coords: {
              x: 908,
              y: 423,
              w: 268,
              h: 477
            },
            _id: '6478c036b1dcda0013e4ed5f',
            name: 'Consolidation'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/5.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 866.492,
              y: 251.28,
              w: 150.092,
              h: 72.864
            },
            name: 'Bone Lesion'
          },
          {
            coords: {
              x: 783.265,
              y: 182.133,
              w: 187.753,
              h: 372.096
            },
            name: 'Mass'
          }
        ]
      },
      reference: {
        time: 45437,
        diagnosis: 'Posterior mediastinal mass',
        pathologies: [
          {
            coords: {
              x: 838,
              y: 240,
              w: 157,
              h: 72
            },
            _id: '64777267b1dcda0013e4ece6',
            name: 'Bone Lesion'
          },
          {
            coords: {
              x: 805,
              y: 177,
              w: 191,
              h: 384
            },
            _id: '64777267b1dcda0013e4ece5',
            name: 'Mass'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/6.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 406.285,
              y: 151.95,
              w: 84.04,
              h: 197.064
            },
            name: 'Lymphadenopathy'
          },
          {
            coords: {
              x: 421.135,
              y: 342.3,
              w: 75.6,
              h: 141.521
            },
            name: 'Lymphadenopathy'
          },
          {
            coords: {
              x: 640.974,
              y: 251.04,
              w: 83.952,
              h: 180.69
            },
            name: 'Infiltrates'
          },
          {
            coords: {
              x: 300.425,
              y: 193.919,
              w: 105.949,
              h: 394.596
            },
            name: 'Infiltrates'
          }
        ]
      },
      reference: {
        time: 54835,
        diagnosis: 'Lymphoma',
        pathologies: [
          {
            coords: {
              x: 415,
              y: 150,
              w: 88,
              h: 204
            },
            _id: '647772fdb1dcda0013e4eceb',
            name: 'Lymphadenopathy'
          },
          {
            coords: {
              x: 403,
              y: 350,
              w: 72,
              h: 137
            },
            _id: '647772fdb1dcda0013e4ecea',
            name: 'Lymphadenopathy'
          },
          {
            coords: {
              x: 634,
              y: 240,
              w: 88,
              h: 190
            },
            _id: '647772fdb1dcda0013e4ece9',
            name: 'Infiltrates'
          },
          {
            coords: {
              x: 305,
              y: 187,
              w: 101,
              h: 388
            },
            _id: '647772fdb1dcda0013e4ece8',
            name: 'Infiltrates'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/7.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 60.884,
              y: 114.716,
              w: 218.192,
              h: 482.653
            },
            name: 'Nodules'
          },
          {
            coords: {
              x: 451.53,
              y: 97.263,
              w: 284.7,
              h: 438.9
            },
            name: 'Nodules'
          }
        ]
      },
      reference: {
        time: 38019,
        diagnosis: 'Diff Diagnosis :MiliaryTB, Histoplasmosis',
        pathologies: [
          {
            coords: {
              x: 62,
              y: 119,
              w: 208,
              h: 491
            },
            _id: '64777427b1dcda0013e4ecee',
            name: 'Nodules'
          },
          {
            coords: {
              x: 435,
              y: 101,
              w: 292,
              h: 462
            },
            _id: '64777427b1dcda0013e4eced',
            name: 'Nodules'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/9.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 522.501,
              y: 232.764,
              w: 180.6,
              h: 289.575
            },
            name: 'Pneumothorax'
          }
        ]
      },
      reference: {
        time: 42891,
        diagnosis: 'Tension pneumothorax',
        pathologies: [
          {
            coords: {
              x: 537,
              y: 238,
              w: 175,
              h: 297
            },
            _id: '6478c38cb1dcda0013e4ed77',
            name: 'Pneumothorax'
          },
          {
            coords: {
              x: 458,
              y: 499,
              w: 177,
              h: 178
            },
            _id: '6478c38cb1dcda0013e4ed76',
            name: 'Hernia'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/11.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 256.27,
              y: 317.156,
              w: 69.48,
              h: 111.55
            },
            name: 'Atelectasis'
          },
          {
            coords: {
              x: 207.557,
              y: 339.15,
              w: 108.712,
              h: 97.44
            },
            name: 'Consolidation'
          }
        ]
      },
      reference: {
        time: 57240,
        diagnosis: 'Rt middle lobe collapse, consolidation',
        pathologies: [
          {
            coords: {
              x: 245,
              y: 329,
              w: 72,
              h: 115
            },
            _id: '647786a6b1dcda0013e4ed49',
            name: 'Atelectasis'
          },
          {
            coords: {
              x: 199,
              y: 350,
              w: 107,
              h: 96
            },
            _id: '647786a6b1dcda0013e4ed48',
            name: 'Consolidation'
          },
          {
            coords: {
              x: 319,
              y: 181,
              w: 57,
              h: 85
            },
            _id: '647786a6b1dcda0013e4ed47',
            name: 'ETT low'
          }
        ]
      }
    },
    {
      image: '/img/phaseOne/12.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 212.42,
              y: 157.92,
              w: 65.394,
              h: 437.144
            },
            name: 'Pleural Effusion'
          },
          {
            coords: {
              x: 284.394,
              y: 347.115,
              w: 142.82,
              h: 174.484
            },
            name: 'Consolidation'
          },
          {
            coords: {
              x: 352.98,
              y: 307.406,
              w: 79.002,
              h: 126.49
            },
            name: 'Lymphadenopathy'
          }
        ]
      },
      reference: {
        time: 40129,
        diagnosis: 'Right Lower lobe pneumonia and parapneumonic effusion',
        pathologies: [
          {
            coords: {
              x: 215,
              y: 160,
              w: 63,
              h: 424
            },
            _id: '6478c2d5b1dcda0013e4ed74',
            name: 'Pleural Effusion'
          },
          {
            coords: {
              x: 278,
              y: 365,
              w: 148,
              h: 181
            },
            _id: '6478c2d5b1dcda0013e4ed73',
            name: 'Consolidation'
          },
          {
            coords: {
              x: 370,
              y: 314,
              w: 77,
              h: 130
            },
            _id: '6478c2d5b1dcda0013e4ed72',
            name: 'Lymphadenopathy'
          }
        ]
      }
    }
  ],
  phaseTwo: [
    {
      image: '/img/phaseTwo/13.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 566.037,
              y: 210.76,
              w: 119.132,
              h: 325.38
            },
            name: 'Calcified Nodules'
          }
        ]
      },
      reference: {
        time: 55125,
        diagnosis: 'Varicella Pneumonia',
        pathologies: [
          {
            coords: {
              x: 577,
              y: 220,
              w: 116,
              h: 330
            },
            _id: '64777b25b1dcda0013e4ed0c',
            name: 'Calcified Nodules'
          },
          {
            coords: {
              x: 326,
              y: 231,
              w: 140,
              h: 343
            },
            _id: '64777b25b1dcda0013e4ed0b',
            name: 'Calcified Nodules'
          }
        ]
      }
    },
    {
      image: '/img/phaseTwo/14.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 108.02,
              y: 350.03,
              w: 902.957,
              h: 473.796
            },
            name: 'Cardiomegaly'
          }
        ]
      },
      reference: {
        time: 25481,
        diagnosis: 'Congenital Cardiac Tetralogy of Fallot',
        pathologies: [
          {
            coords: {
              x: 110,
              y: 355,
              w: 913,
              h: 492
            },
            _id: '64777c93b1dcda0013e4ed10',
            name: 'Cardiomegaly'
          },
          {
            coords: {
              x: 261,
              y: 304,
              w: 173,
              h: 164
            },
            _id: '64777c93b1dcda0013e4ed0f',
            name: 'Right sided aortic arch'
          }
        ]
      }
    },
    {
      image: '/img/phaseTwo/15.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 8.248,
              y: 9.315,
              w: 1244.39,
              h: 969.441
            },
            name: 'Hyperinflation'
          },
          {
            coords: {
              x: 675.816,
              y: 709.07,
              w: 135.082,
              h: 138.852
            },
            name: 'Cavity'
          }
        ]
      },
      reference: {
        time: 52050,
        diagnosis: 'Boshdalek hernia',
        pathologies: [
          {
            coords: {
              x: 8,
              y: 9,
              w: 1226,
              h: 1013
            },
            _id: '64777dd0b1dcda0013e4ed18',
            name: 'Hyperinflation'
          },
          {
            coords: {
              x: 696,
              y: 731,
              w: 137,
              h: 133
            },
            _id: '64777dd0b1dcda0013e4ed17',
            name: 'Cavity'
          }
        ]
      }
    },
    {
      image: '/img/phaseTwo/16.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 327.02,
              y: 346.46,
              w: 116.928,
              h: 126.232
            },
            name: 'Lymphadenopathy'
          },
          {
            coords: {
              x: 161.865,
              y: 423.546,
              w: 238.359,
              h: 169.338
            },
            name: 'Consolidation'
          }
        ]
      },
      reference: {
        time: 47961,
        diagnosis: 'Primary TB with multilobar pneumonia',
        pathologies: [
          {
            coords: {
              x: 332,
              y: 340,
              w: 112,
              h: 124
            },
            _id: '647781bdb1dcda0013e4ed2c',
            name: 'Lymphadenopathy'
          },
          {
            coords: {
              x: 165,
              y: 438,
              w: 233,
              h: 167
            },
            _id: '647781bdb1dcda0013e4ed2b',
            name: 'Consolidation'
          },
          {
            coords: {
              x: 523,
              y: 258,
              w: 80,
              h: 133
            },
            _id: '647781bdb1dcda0013e4ed2a',
            name: 'Lymphadenopathy'
          },
          {
            coords: {
              x: 597,
              y: 315,
              w: 104,
              h: 100
            },
            _id: '647781bdb1dcda0013e4ed29',
            name: 'Lymphadenopathy'
          },
          {
            coords: {
              x: 590,
              y: 435,
              w: 242,
              h: 156
            },
            _id: '647781bdb1dcda0013e4ed28',
            name: 'Consolidation'
          }
        ]
      }
    },
    {
      image: '/img/phaseTwo/17.png',
      edai: {
        pathologies: []
      },
      reference: {
        time: 33849,
        diagnosis: 'Normal',
        pathologies: []
      }
    },
    {
      image: '/img/phaseTwo/19.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 188.762,
              y: 281.374,
              w: 129.035,
              h: 99.619
            },
            name: 'Cavity'
          }
        ]
      },
      reference: {
        time: 27157,
        diagnosis: 'RLL pneumonia with abscess',
        pathologies: [
          {
            coords: {
              x: 194,
              y: 269,
              w: 131,
              h: 97
            },
            _id: '6477845db1dcda0013e4ed3a',
            name: 'Cavity'
          },
          {
            coords: {
              x: 114,
              y: 261,
              w: 178,
              h: 223
            },
            _id: '6477845db1dcda0013e4ed39',
            name: 'Consolidation'
          },
          {
            coords: {
              x: 304,
              y: 408,
              w: 52,
              h: 72
            },
            _id: '6477845db1dcda0013e4ed38',
            name: 'Cavity'
          }
        ]
      }
    },
    {
      image: '/img/phaseTwo/20.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 178.188,
              y: 82.96,
              w: 125.44,
              h: 111.036
            },
            name: 'Bone Lesion'
          }
        ]
      },
      reference: {
        time: 42859,
        diagnosis: 'Rickets',
        pathologies: [
          {
            coords: {
              x: 186,
              y: 80,
              w: 128,
              h: 114
            },
            _id: '647784f4b1dcda0013e4ed3e',
            name: 'Bone Lesion'
          },
          {
            coords: {
              x: 850,
              y: 347,
              w: 113,
              h: 130
            },
            _id: '647784f4b1dcda0013e4ed3d',
            name: 'Bone Lesion'
          },
          {
            coords: {
              x: 889,
              y: 328,
              w: 169,
              h: 404
            },
            _id: '647784f4b1dcda0013e4ed3c',
            name: 'Bone Lesion'
          }
        ]
      }
    },
    {
      image: '/img/phaseTwo/21.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 206.562,
              y: 330.26,
              w: 85.885,
              h: 296.672
            },
            name: 'Consolidation'
          }
        ]
      },
      reference: {
        time: 44578,
        diagnosis: 'Rt middle lobe collapse/consolidation',
        pathologies: [
          {
            coords: {
              x: 199,
              y: 337,
              w: 89,
              h: 292
            },
            _id: '64778545b1dcda0013e4ed41',
            name: 'Consolidation'
          },
          {
            coords: {
              x: 172,
              y: 336,
              w: 103,
              h: 269
            },
            _id: '64778545b1dcda0013e4ed40',
            name: 'Atelectasis'
          }
        ]
      }
    },
    {
      image: '/img/phaseTwo/22.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 426.76800000000003,
              y: 434.472,
              w: 611.1,
              h: 487.05
            },
            name: 'Cardiomegaly'
          }
        ]
      },
      reference: {
        time: 31261,
        diagnosis: 'Congenital Cardiac, L-R shunt',
        pathologies: [
          {
            coords: {
              x: 408,
              y: 421,
              w: 582,
              h: 510
            },
            _id: '64777d59b1dcda0013e4ed15',
            name: 'Cardiomegaly'
          },
          {
            coords: {
              x: 123,
              y: 260,
              w: 1019,
              h: 843
            },
            _id: '64777d59b1dcda0013e4ed14',
            name: 'Hyperinflation'
          }
        ]
      }
    },
    {
      image: '/img/phaseTwo/24.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 398.05,
              y: 295.076,
              w: 524.295,
              h: 525.672
            },
            name: 'Cardiomegaly'
          },
          {
            coords: {
              x: 47.025,
              y: 83.04,
              w: 901.44,
              h: 892.564
            },
            name: 'Hyperinflation'
          }
        ]
      },
      reference: {
        time: 25063,
        diagnosis: 'Vacteral',
        pathologies: [
          {
            coords: {
              x: 463,
              y: 305,
              w: 104,
              h: 94
            },
            _id: '647776c2b1dcda0013e4ecfa',
            name: 'Bone Lesion'
          },
          {
            coords: {
              x: 413,
              y: 646,
              w: 170,
              h: 103
            },
            _id: '647776c2b1dcda0013e4ecf9',
            name: 'Bone Lesion'
          },
          {
            coords: {
              x: 419,
              y: 284,
              w: 549,
              h: 504
            },
            _id: '647776c2b1dcda0013e4ecf8',
            name: 'Cardiomegaly'
          },
          {
            coords: {
              x: 45,
              y: 80,
              w: 939,
              h: 908
            },
            _id: '647776c2b1dcda0013e4ecf7',
            name: 'Hyperinflation'
          }
        ]
      }
    }
  ]
}

const actions = {}

const mutations = {}

export const challenge = {
  namespaced: true,
  state,
  actions,
  mutations
}
